/* eslint-disable react/display-name */
import React, { Fragment, useRef, useState } from 'react'

import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { GatsbyImage } from "gatsby-plugin-image";

// components
import Category from '../../components/Category/category'
import Layout from '../../components/Layout/index'
import CategoryMobile from '../../components/Category/categoryMobile'
import CardOferta from '../../components/cardOfertas/cardOfertas'
import Instagram from '../../components/Instagram/instagram'
import ValidDate from '../../components/validDate/validDate'

// styles
import './post-blog.scss'

// images
import facebook from '../../images/icons/post-blog/facebook.svg'
import pinterest from '../../images/icons/post-blog/pinterest.svg'
import twitter from '../../images/icons/post-blog/twitter.svg'
import setaVoltar from '../../images/icons/post-blog/seta-voltar.svg'

const Title = ({ props }) => {
  return (
    <>
      <div className='title-post-blog'>
        <ValidDate date={props.data.blog.date} />
        <h2 className='mt-2 font-baloo-extrabold mb-2'>{props.data.blog.title}</h2>
      </div>
    </>
  )
}

const PostBlog = (props) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Institucional' },
      { path: '/blog/', name: 'Blog' }
    ]
  }

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        // If you are using contenful.js sdk, the referenced entry is resolved
        // automatically and is available at `node.data.target`.

        return <a href={`${node.data.uri}`} target="_blank" without rel="noreferrer"><u>{children}</u></a>
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const title = node.data.target.title
        const alt = node.data.target.description
        const url = node.data.target.localFile.url
        const [toggler, setToggler] = useState(false)
        return <figure className='figure'>
          <a onClick={() => setToggler(!toggler)}>
            <img className='figure-img img-fluid rounded border' alt={alt} src={url} title={title} />
          </a>
        </figure>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <p className='mb-3'>{children}</p>
      }
    },
    renderMark: {
      // eslint-disable-next-line react/display-name
      [MARKS.CODE]: text => {
        return <p>{text}</p>
      }
    }
  }

  const [currentMarket, setCurrentMarket] = useState(typeof window !== 'undefined' ? JSON.parse(window.localStorage.getItem('currentMarket')) : null)
  const modalRef = useRef()
  const { content } = props.data.blog
  const pageUrl = props.data.site.siteMetadata.siteUrl + '/blog/' + props.data.blog.slug
  const imageSrc = props.data.site.siteMetadata.siteUrl + props.data.blog.card.localFile.childImageSharp.gatsbyImageData.src
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pt" />
        <title>{props.data.blog.title} | Spani</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={`${props.data.blog.subtitle}`} />
        <meta name="author" content="SPANI Supermercados" />
        {props.data.blog.tag !== null &&
          <meta name="keywords" content={props.data.blog.tag.join(', ')} />
        }
        <meta name="robots" content="index, follow" />
        <meta property="og:description" content={`${props.data.blog.subtitle}`} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={props.data.blog.title} />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:image" content={imageSrc} />
        <meta property="og:site_name" content='SPANI Supermercados' />
        <meta property="og:url" content={pageUrl} />
        <meta property="article:author" content="SPANI Supermercados" />
        <meta property="article:section" content={props.data.blog.subtitle} />
        {/* fazer depois
        <meta property="fb:app_id" content={data.site.siteMetadata.facebookAppId}/> */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${pageUrl}"
              },
              "headline": "${props.data.blog.title}",
              "description": "${props.data.blog.subtitle}",
              "image": [
                "${imageSrc}"
              ],
              "datePublished": "${props.data.blog.date}",
              "dateModified": "${props.data.blog.updatedAt}",
              "publisher": {
                "@type": "Organization",
                "name": "Spani",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://www.spani.com.br/spani-logo.png"
                }
              }
            }
          `}
        </script>
      </Helmet>
      <Layout breadCrumb={breadC} homeCallback={setCurrentMarket} ref={modalRef}>
        <div className='bg-gray'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 pt-4'>
                <CategoryMobile></CategoryMobile>
              </div>
            </div>
            <div className='row'>
              <div className='col-10'>
                <h1 className='font-source-bold m-0'>Blog Do Spani</h1>
              </div>
              <div className='col-2 d-flex justify-content-end align-items-center'>
                <a href="javascript:history.back()" className="d-flex align-items-center rounded px-sm-3 px-2 py-sm-1 py-2">
                  <img src={setaVoltar} alt="seta de voltar" className="m-0 mr-sm-2" />
                  <span className="text-uppercase d-none d-sm-block">Voltar</span>
                </a>
              </div>
            </div>
          </div>
          <div className='container mt-4'>
            <div className='row align-items-end d-lg-flex d-none'>
              <div className='col-lg-8 col-12'>
                <Title props={props}></Title>
              </div>
              <div className='col-lg-4 col-12'>
                <h2 className='font-baloo-extrabold mt-4 mb-2'>Categorias</h2>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-8 col-12'>
                <div className='row'>
                  {props.data.blog.imagemdestaque !== null &&
                    <div className='col-12 mb-4'>
                      <GatsbyImage
                        image={props.data.blog.imagemdestaque.localFile.childImageSharp.gatsbyImageData}
                        alt={props.slug} />
                    </div>
                  }
                  <div className='col-12'>
                    {props.data.blog.urlVideo !== null &&
                      <div className="player-wrapper">
                        <ReactPlayer
                          url={`${props.data.blog.urlVideo}`}
                          className="react-player"
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                    }
                    <div className='d-flex d-lg-none mt-3'>
                      <Title props={props} ></Title>
                    </div>
                    <p className='m-0 mt-lg-4 mb-4' >
                      {/* {props.data.blog.content && renderRichText(richTextField, options)} */}
                      {content && renderRichText(content, options)}
                    </p>
                    <div className='row p-0 m-0'>
                      {props.data.blog.tag &&
                        props.data.blog.tag.map(tags => {
                          return (
                            <p className='font-openSans-italic font-weight-bold pb-0 pb-sm-2'>
                              #{tags}&nbsp;&nbsp;
                            </p>
                          )
                        })
                      }
                    </div>
                    <hr />
                    <div className='d-flex justify-content-start align-items-center pt-0 pt-sm-3 mb-5'>
                      <p className='mr-2 font-openSans-italic font-weight-bold'>Compartilhe</p>
                      <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}`} target="_blank" without rel="noreferrer">
                        <img src={facebook} height='30px' width='30px' className='mr-2 ml-2' alt='facebook' />
                      </a>
                      <a href={`https://twitter.com/intent/tweet?url=${pageUrl}`} target="_blank" without rel="noreferrer">
                        <img src={twitter} height='30px' width='30px' className='mr-2 ml-2' alt='twitter' />
                      </a>
                      <a href={`https://www.pinterest.com/pin/create/link/?url=${pageUrl}&description=Spani Supermercados`} target="_blank" without rel="noreferrer">
                        <img src={pinterest} height='30px' width='30px' className='mr-2 ml-2' alt='pinterest' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
                <div className='row'>
                  <div className='col-12'>
                    <Category path={props.data.blog.slug}></Category>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.data.infoCards.edges.length !== 0 &&
            <div className='pt-4'>
              <CardOferta modal={modalRef} current={currentMarket} info={props.data.infoCards}></CardOferta>
            </div>
          }
          <div className='container d-block d-lg-none'>
            <div className='row'>
              <div className='col-12 mt-4 mb-3'>
                <hr className='d-block d-lg-none' />
              </div>
            </div>
          </div>
          <Instagram></Instagram>
        </div>
      </Layout>
    </Fragment>
  );
}
export default PostBlog
export const query = graphql`query ($slug: String!, $data: Date!) {
  blog: contentfulBlog(slug: {eq: $slug}) {
    slug
    title
    subtitle
    date
    updatedAt
    urlVideo
    tag
    imagemdestaque {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    content {
      raw
    }
    card {
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  }
  infoCards: allContentfulOfertas(
    filter: {dataInicio: {lte: $data}, dataFim: {gte: $data}}
  ) {
    edges {
      node {
        nome
        dataInicio
        dataFim
        ofertasCards {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`
